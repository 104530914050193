<template lang="pug">
.content-container
    //- .section.content-title-group
    //-     h2.title Welcome to the Azure AD + Storage app with Vue.js
    //- div(v-if='account')
    //-     .level
    //-         .level-item.title
    //-             | You are logged in as {{ account.name }}
    //- div(v-else='') You need to authenticate to access your Azure Storage data
    //- headerbarlink

</template>

<script>
import { mapMutations } from 'vuex';
// import headerbarlink from '@/components/links';

export default {
    name: 'HelloWorld',
    components: {
        // headerbarlink
    },

    data: () => ({
        // account: undefined,
        // containers: [],
    }),

    methods: {
        ...mapMutations(['setAccessToken']),
    },

    created () {
        // this.$emitter.on('login', (account) => {
        //     this.account = account;
        //     console.log(this.account);
        // });
        // this.$emitter.on('logout', () => {
        //     console.log('logging out');
        //     this.account = undefined;
        // });
    },

    mounted () {
        // window.vm = this;
    }
};
</script>
